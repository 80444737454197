
import http from "@/common/http";
import { getLabelByValue, GoodsTypes } from "@/common/head";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { pagConfig, searchCol } from "@/config/app";
import ChGoldLogEdit from "./Edit.vue";
import { PaginationProps } from "ant-design-vue";

const columns = [
  // {
  //   title: "id",
  //   width: 100,
  //   dataIndex: "id",
  //   fixed: "left",
  // },

  {
    title: "玩家ID",
    width: 100,
    dataIndex: "mask_id",
    ellipsis: true,
  },

  {
    title: "货币类型",
    dataIndex: "goods_id",
    ellipsis: true,
    width: 90,
  },

  {
    title: "昵称",
    dataIndex: "nickname",
    ellipsis: true,
    width: 150,
  },

  {
    title: "修改前的货币数量",
    dataIndex: "old_gold_amount",
    ellipsis: true,
    width: 150,
  },

  {
    title: "本次修改货币数量",
    dataIndex: "ch_gold_amount",
    ellipsis: true,
    width: 150,
  },

  {
    title: "修改后的货币数量",
    dataIndex: "new_gold_amount",
    ellipsis: true,
    width: 150,
  },

  {
    title: "操作人用户名",
    dataIndex: "opr_username",
    ellipsis: true,
    width: 130,
  },

  {
    title: "备注",
    dataIndex: "desc",
    ellipsis: true,
    width: 180,
  },

  {
    title: "创建时间",
    width: 170,
    dataIndex: "created_at",
  },
  // {
  //   title: "",
  //   key: "operation",
  //   dataIndex: "operation",
  //   fixed: "right",
  //   width: 180,
  //   slots: { customRender: "action" },
  // },
];

export default defineComponent({
  components: {
    ChGoldLogEdit,
  },
  setup() {
    //搜索绑定数据
    const search = reactive({
      //是否显示有的搜索框
      showAll: false,

      //搜索数据
      data: {
        goods_id: undefined,
        im_player_id: undefined,
      },

      //点击搜索后的搜索数据
      relData: {},
    });

    //表格加载中
    const loading = ref(true);

    const pagination = reactive(pagConfig);

    const data = ref([]);

    const edit_ref = ref();

    const editId = ref(0);

    const formRef = ref();

    //是否显示添加/编辑弹窗
    const visible = ref(false);

    //获取列表
    const getList = async () => {
      loading.value = true;
      const result = await http.get("ch_gold_log", {
        page: pagination.current,
        page_size: pagination.pageSize,
        ...search.relData,
      });

      data.value = result.data.data;
      pagination.total = result.data.count;

      loading.value = false;
    };

    //监听页码切换
    const handleTableChange = (pag: PaginationProps) => {
      pagination.current = pag?.current;
      pagination.pageSize = pag?.pageSize;
      getList();
    };

    //点击编辑按钮
    const showEdit = (row: any) => {
      editId.value = row.id;
      visible.value = true;
    };

    //新增/修改成功
    const onEditSuccess = () => {
      getList();
    };

    //点击搜索按钮
    const onSearch = () => {
      pagination.current = 1;
      search.relData = search.data;
      getList();
    };

    //点击重置按钮
    const onReset = () => {
      formRef.value.resetFields();
      onSearch();
    };

    onMounted(() => {
      getList();
    });

    return {
      data,
      visible,
      columns,
      search,
      pagination,
      edit_ref,
      formRef,
      loading,
      handleTableChange,
      showEdit,
      editId,
      onSearch,
      onReset,
      onEditSuccess,
      searchCol,
      GoodsTypes,
      getLabelByValue,
    };
  },
});
