
import { GoodsTypes } from "@/common/head";
import http from "@/common/http";
import { message } from "ant-design-vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "ChGoldLogEdit",
  props: {
    id: {
      type: Number,
    },
  },
  components: {},
  setup(props, { emit }) {
    const formRef = ref();
    const loading = ref(false);
    const contentRef = ref();

    const submitLoading = ref(false);

    const formState = ref({
      im_player_id: "",
      goods_id: undefined,
      ch_gold_amount: "",
      desc: "",
      opr_type: 1,
    });

    const playerInfo = ref({
      nick_name: "",
      beans_amount: "",
    });

    //验证规则
    const rules = {
      im_player_id: [{ required: true, message: "玩家ID不能为空且必须是数字", trigger: "change", type: "number" }],
      goods_id: [{ required: true, message: "请选择货币类型", trigger: "change", type: "number" }],
      ch_gold_amount: [{ required: true, message: "修改的货币数量不能为空，且必须是数字", trigger: "change", type: "number" }],
    };

    //点击取消
    const onClose = () => {
      emit("update:id", 0);
      emit("close");
    };

    //获取数据
    const getData = async () => {
      loading.value = true;
      if (props.id) {
        //获取编辑数据
        const { data } = await http.get(`ch_gold_log/${props.id}`);
        formState.value = data.form_data;
      }

      loading.value = false;
    };

    //获取玩家信息
    const getPlayerInfo = async () => {
      if (formState.value.im_player_id) {
        //获取编辑数据
        const { data } = await http.get(`ch_gold_log/player_info/${formState.value.im_player_id}`);
        playerInfo.value.nick_name = data.nick_name;
        playerInfo.value.beans_amount = data.beans_amount;
      }
    };

    //提交
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        submitLoading.value = true;
        try {
          if (!props.id) {
            //新增
            await http.post("ch_gold_log", formState.value);
            message.success("新增成功");
          } else {
            //修改
            await http.put(`ch_gold_log/${props.id}`, formState.value);
            message.success("修改成功");
          }
        } finally {
          submitLoading.value = false;
        }

        onClose();
        emit("success");
      });
    };

    onMounted(() => {
      getData();
    });

    return {
      formRef,
      contentRef,
      formState,
      rules,
      loading,
      submitLoading,
      GoodsTypes,
      playerInfo,
      onClose,
      handleSubmit,
      getPlayerInfo,
    };
  },
});
